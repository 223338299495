.joboffer_group {
  margin: 10px;
  padding: 10px;
  font-weight: 600;
}

.card {
  cursor: pointer;
  background: #364e7b;
  border-radius: 10px;
  padding: 5px 80px 5px 36px;
  transition: transform .3s cubic-bezier(.155, 1.105, .295, 1.12), box-shadow .5s, -webkit-transform .3s cubic-bezier(.155, 1.105, .295, 1.12);
  box-shadow: .3rem .3rem 1rem #253e58;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: .3rem .3rem 1.6rem #253e58;
}

.card h3 {
  letter-spacing: .4rem;
  margin: 10px 0;
  font-weight: 400;
}

.card-1 {
  width: 50%;
  background-image: url("arrow.028784ad.svg");
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 40px;
}

a .card {
  color: #fff;
  margin-bottom: 20px;
}

.job_offerLink:hover {
  text-decoration: none;
}

.job_offer_header {
  color: #364e7b;
  letter-spacing: .5rem;
  border-bottom: 1px solid #000;
  margin: 25px 30px 0;
  font-size: 2rem;
}

.we_hire_div {
  color: #ffc107;
  padding: 14px 16px;
  font-size: 17px;
}

.join_us_container {
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 20px;
}

.join_us_section {
  width: 1440px;
  max-width: 100%;
  margin: auto;
}

.join_us_header {
  background-image: url("recruitment_1.74e45a06.jpg");
  background-size: cover;
  padding-bottom: 70px;
}

.join_us_header__section {
  max-width: 520px;
  margin: 0 62px;
  padding: 76px 0 0;
  font-size: 16px;
}

.join_us_header__section__header {
  font-size: 3.2rem;
  font-weight: 400;
}

.join_us_offers {
  background-color: #fff;
}

.join_us_offers__section {
  margin-left: 62px;
  padding-top: 26px;
  padding-bottom: 50px;
}

.join_us_header__section__text {
  font-size: 1.7rem;
}

.join_us_offers__section__offers {
  padding-top: 20px;
}

.join_us_offers__NoActiveHire {
  margin-top: 2.8rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.flex_header {
  flex-direction: row;
  place-content: flex-start;
  align-items: center;
  display: flex;
}

.flex_header img {
  margin-right: 15px;
}

.prod_section__subheader {
  text-transform: uppercase;
  font-size: 1.9rem;
  font-weight: 600;
}

.aboutus_content_box {
  padding: 10px 55px;
}

@media (max-width: 768px) {
  .job_offerLink {
    width: 90%;
  }

  .card-1 {
    width: 100%;
  }

  .card h3 {
    font-size: 18px;
  }

  .join_us_offers__section {
    margin-left: 0;
  }

  .join_us_header__section__header {
    text-align: center;
  }

  .join_us_offers__section__offers {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.careerInfoCard, .careerInfoCard header {
  border-radius: 7px;
}

.careerInfoCard header {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/*# sourceMappingURL=index.8431e2f3.css.map */
