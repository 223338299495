.joboffer_group {
	padding: 10px;
	margin: 10px;
	font-weight: 600;
}

.card {
	border-radius: 10px;
	background: #364e7b;
	/* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05); */
	box-shadow: 0.3rem 0.3rem 1rem #253e58;
	transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.5s box-shadow,
		0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
	padding: 5px 80px 5px 36px;
	cursor: pointer;
	/* border: 3px solid #76a3f6; */
}

.card:hover {
	transform: scale(1.05);
	/* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
	box-shadow: 0.3rem 0.3rem 1.6rem #253e58;
}

.card h3 {
	font-weight: 400;
	letter-spacing: 0.4rem;
	margin: 10px 0;
}

.card-1 {
	background-image: url(../img/arrow.svg);
	background-repeat: no-repeat;
	background-position: right 15px center;
	background-size: 40px;
	/* margin: 30px; */
	width: 50%;
	/* min-width: 200px; */
}

a .card {
	color: #ffffff;
	margin-bottom: 20px;
}
.job_offerLink:hover {
	text-decoration: none;
}

.job_offer_header {
	color: #364e7b;
	margin: 25px 30px 0 30px;
	font-size: 2rem;
	border-bottom: 1px solid black;
	letter-spacing: 0.5rem;
}
.we_hire_div {
	padding: 14px 16px;
	font-size: 17px;
	color: #ffc107;
}

.join_us_container {
	background-color: #f2f2f2;
	padding-top: 20px;
	padding-bottom: 20px;
}
.join_us_section {
	width: 1440px;
	max-width: 100%;
	margin: auto;
}

.join_us_header {
	background-image: url(../img/recruitment_1.jpg);
	background-size: cover;
	padding-bottom: 70px;
}

.join_us_header__section {
	max-width: 520px;
	margin: 0 62px;
	padding: 76px 0 0 0;
	font-size: 16px;
}

.join_us_header__section__header {
	font-size: 3.2rem;
	font-weight: 400;
}
.join_us_offers {
	background-color: #fff;
}
.join_us_offers__section {
	margin-left: 62px;
	padding-top: 26px;
	padding-bottom: 50px;
}

.join_us_header__section__text {
	font-size: 1.7rem;
}

.join_us_offers__section__offers {
	padding-top: 20px;
}

.join_us_offers__NoActiveHire {
	font-size: 1.8rem;
	font-weight: 600;
	margin-top: 2.8rem;
}

.flex_header {
	display: flex;
	align-content: flex-start;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
}

.flex_header img {
	margin-right: 15px;
}

.prod_section__subheader {
	text-transform: uppercase;
	font-size: 1.9rem;
	font-weight: 600;
}

.aboutus_content_box {
	padding: 10px 55px;
}

@media (max-width: 768px) {
	.job_offerLink {
		width: 90%;
	}
	.card-1 {
		width: 100%;
	}
	.card h3 {
		font-size: 18px;
	}
	.join_us_offers__section {
		margin-left: 0;
	}

	.join_us_header__section__header {
		text-align: center;
	}

	.join_us_offers__section__offers {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.careerInfoCard,
.careerInfoCard header {
	border-radius: 7px;
}

.careerInfoCard header {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
